import styled from "styled-components";
import BG from "assets/img/BG.png";

import media from "constants/media";

const Page = styled.div`
  background-image: url(${BG});
  background-size: cover;
  background-color: var(--backgroudGrey);
  display: flex;
  flex-flow: column;
  min-height: 100vh;
`;

const Container = styled.div`
  width: 1170px;
  margin: 0 auto;
  ${media.xl} {
    max-width: 90%;
  }
`;

const Content = styled.div`
  flex: 1;
  padding: 0 0 50px;
`;

export default {
  Page,
  Content,
  Container,
};
