import {
  GET_PROGRESS_SUCCESS,
  GET_COURSE_SUCCESS,
  SUBMIT_COURSE_QUIZ_SUCCESS,
  CLEAR_QUIZ_RESULT,
  SUBMIT_FEEDBACK_SUCCESS,
} from "./course.actions";
import initialState from "../../store/initialState";

export default (state = initialState.course, action) => {
  switch (action.type) {
    case GET_PROGRESS_SUCCESS:
      return {
        ...state,
        progress: action.payload.progress,
      };
    case GET_COURSE_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case SUBMIT_COURSE_QUIZ_SUCCESS:
      return {
        ...state,
        quizResults: action.payload,
      };
    case SUBMIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedback: action.payload,
      };
    case CLEAR_QUIZ_RESULT:
      return {
        ...state,
        quizResults: null,
      };
    default:
      return state;
  }
};
