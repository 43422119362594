import styled from "styled-components";

const Card = styled.div`
  padding: 25px 0 60px;
  background: #fff;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  margin-top: 25px;
`;

const CardHeader = styled.div`
  padding: 0 30px 25px;
  border-bottom: 1px solid #d6dee1;
`;

const CardContent = styled.div`
  padding-top: 40px;
  max-width: 550px;
  margin: 0 auto;
  padding: 15px 15px 0;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export default {
  Card,
  CardContent,
  CardHeader,
  Title,
  ButtonRow,
};
