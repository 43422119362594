import { call, put, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";

import {
  finishLoaderAction,
  startLoaderAction,
} from "services/loader/loader.action";

import {
  GET_PROGRESS,
  GET_PROGRESS_SUCCESS,
  GET_PROGRESS_FAILURE,
  GET_COURSE_SUCCESS,
  GET_COURSE,
  GET_COURSE_FAILURE,
  UPDATE_PROGRESS,
  UPDATE_PROGRESS_SUCCESS,
  UPDATE_PROGRESS_FAILURE,
  SUBMIT_COURSE_QUIZ,
  SUBMIT_COURSE_QUIZ_SUCCESS,
  SUBMIT_COURSE_QUIZ_FAILURE,
  CLEAR_QUIZ_RESULT,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_FAILURE,
  SUBMIT_FEEDBACK,
} from "./course.actions";

import {
  courseProgressRequest,
  courseRequest,
  updateCourseProgressRequest,
  submitCourseQuizRequest,
  submitFeedbackRequest,
} from "./course.api";
import { isUrlMatch } from "../router/router.utils";
import { urlLocations } from "routes/urlLocations";

function* getCourseOnLocationsChange({ payload }) {
  try {
    if (
      isUrlMatch(payload, urlLocations.course) ||
      isUrlMatch(payload, urlLocations.moduleInfo) ||
      isUrlMatch(payload, urlLocations.lessonInfo) ||
      isUrlMatch(payload, urlLocations.main)
    ) {
      yield put({ type: GET_COURSE });
      yield put({ type: GET_PROGRESS });
      yield put({ type: CLEAR_QUIZ_RESULT });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchCourse() {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(courseRequest);
    yield put({
      type: GET_COURSE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: GET_COURSE_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* fetchCourseProgress() {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(courseProgressRequest);
    yield put({
      type: GET_PROGRESS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: GET_PROGRESS_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* updateCourseProgress({ payload: id }) {
  try {
    yield put(startLoaderAction());
    yield call(updateCourseProgressRequest, id);
    yield put({
      type: UPDATE_PROGRESS_SUCCESS,
    });
    yield put({
      type: GET_PROGRESS,
    });
  } catch (error) {
    yield put({ type: UPDATE_PROGRESS_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* submitCourseQuiz({ payload }) {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(submitCourseQuizRequest, payload);
    yield put({
      type: SUBMIT_COURSE_QUIZ_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: UPDATE_PROGRESS,
      payload: payload?.id,
    });
  } catch (error) {
    yield put({ type: SUBMIT_COURSE_QUIZ_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* submitFeedback({ payload }) {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(submitFeedbackRequest, payload);
    yield put({
      type: SUBMIT_FEEDBACK_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: UPDATE_PROGRESS,
      payload: payload?.id,
    });
  } catch (error) {
    yield put({ type: SUBMIT_FEEDBACK_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* courseSaga() {
  yield takeEvery(LOCATION_CHANGE, getCourseOnLocationsChange);
  yield takeEvery(GET_COURSE, fetchCourse);
  yield takeEvery(GET_PROGRESS, fetchCourseProgress);
  yield takeEvery(UPDATE_PROGRESS, updateCourseProgress);
  yield takeEvery(SUBMIT_COURSE_QUIZ, submitCourseQuiz);
  yield takeEvery(SUBMIT_FEEDBACK, submitFeedback);
}
