import styled, { css } from "styled-components";
import { Button as AntdButton } from "antd";

import media from "constants/media";

export default styled(AntdButton)`
  &&& {
    padding: 16px 90px;
    line-height: 1.5;
    padding-top: 16px !important;
    ${media.s} {
      padding: 10px 50px;
      width: 100%;
    }
    .anticon {
      line-height: 1;
    }
    img {
      margin-left: 5px;
    }

    ${({ primary }) =>
      primary &&
      css`
        padding: 16px 24px !important;
        background: #fff;
        color: var(--green);
        border-color: var(--green);
        &:hover {
          color: var(--blue);
          border-color: var(--blue);
        }
      `}

    ${({ simplebutton }) =>
      simplebutton &&
      `${css`
        padding: 8px 16px !important;
        background: #fff;
        color: var(--blue);
        height: 40px;
        border-color: #fff;
        box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
        &:hover {
          box-shadow: 0px 10px 25px rgba(5%, 5%, 5%, 0.1);
        }
      `}`}
  }
  background: var(--orange);
  color: #fff;
  height: 56px;
  border-radius: 7px;
  border-color: var(--orange);
  & > * {
    vertical-align: middle;
  }
  &:active,
  &:focus,
  &:hover {
    background: #fff;
    color: var(--orange);
    border-color: var(--orange);
  }
`;
