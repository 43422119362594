import { all } from "redux-saga/effects";

import loginSaga from "services/login/login.saga";
import logoutSaga from "services/logout/logout.saga";
import modalsSaga from "services/modals/modals.saga";
import loaderSaga from "services/loader/loader.saga";
import appInfoSaga from "services/appInfo/appInfo.saga";
import courseSaga from "services/course/course.saga";
import lessonSaga from "services/lesson/lesson.saga";
import profileSaga from "services/profile/profile.saga";
import fileUploadSaga from "services/filesUpload/filesUpload.saga";
import askQuestionSaga from "services/askQuestion/askQuestion.saga";
import logsSaga from "services/logs/logs.saga";

export default function* rootSaga() {
  yield all([
    appInfoSaga(),
    loginSaga(),
    logoutSaga(),
    modalsSaga(),
    loaderSaga(),
    courseSaga(),
    lessonSaga(),
    profileSaga(),
    fileUploadSaga(),
    askQuestionSaga(),
    logsSaga(),
  ]);
}
