import styled from "styled-components";

const Content = styled.div`
  text-align: center;
  padding: 45px 0;
`;

const Wrap = styled.div`
  max-width: 80%;
  margin: 0 auto;
`;

const Icon = styled.div`
  padding: 32px 38px;
  border: 1px solid var(--lightBlue);
  display: inline-block;
  border-radius: 20px;
  margin-bottom: 32px;
  .anticon {
    color: var(--blue);
    svg {
      width: 32px;
      height: 32px;
    }
  }
`;

const Description = styled.div`
  margin-bottom: 32px;
`;

export default {
  Content,
  Wrap,
  Icon,
  Description,
};
