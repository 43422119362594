import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import ReactMarkdown from "react-markdown";
import {useDispatch, useSelector} from "react-redux";

import Styled from "../Lesson.Styled";
import { updateVideoLogsAction } from "services/logs/logs.actions";
import {courseProgressSelector} from "services/course/course.selectors";
import {updateProgressAction} from "services/course/course.actions";

const LessonVideo = React.memo(({ lesson }) => {
  let videoRef = useRef(null);
  let currentTimeRef = useRef(0);

  const finishedLessons = useSelector(courseProgressSelector)?.finishedLessons;
  const [isStopped, setVideoState] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      !isStopped && setCurrentTime(currentTime + 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [currentTime, isStopped]);

  useEffect(() => {
    currentTimeRef.current = currentTime;
  }, [currentTime])

  useLayoutEffect(() => {
    return () => {
      sendCurrentVideoTime();
    }
  }, []);

  const sendCurrentVideoTime = () => {
    !!currentTimeRef.current &&
      dispatch(
        updateVideoLogsAction({
          lesson: lesson?.id,
          secondsWatched: Math.round(currentTimeRef.current),
        })
      );
  };

  const onPlay = () => {
    videoRef.current.play();
    setVideoState(false);
    !finishedLessons.includes(lesson?.id) && dispatch(updateProgressAction(lesson?.id ))
  };
  const onPause = () => {
    videoRef.current.pause();
    setVideoState(true);
  };

  return (
    <>
      <ReactMarkdown>{lesson?.Description}</ReactMarkdown>
      <Styled.VideoWrapper>
        <video
          ref={(r) => {
            if (!!r) videoRef.current = r;
          }}
          controls={true}
          src={lesson?.material?.[0]?.video?.url}
          onPlay={onPlay}
          onPause={onPause}
        >
          <source src={lesson?.material?.[0]?.video?.url} />
        </video>
      </Styled.VideoWrapper>
    </>
  );
});

export default LessonVideo;
