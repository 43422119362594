import Login from "./Login/Login";
import Main from "./Main/Main";
import Course from "./Course/Course";
import Module from "./Module/Module";
import Lesson from "./Lesson/Lesson";
import AskQuestion from "./AskQuestion/AskQuestion";
import Privacy from "./Privacy/Privacy";
import Terms from "./Terms/Terms";
import FinalCongratulation from "./FinalCongratulation/FinalCongratulation";

export default {
  Login,
  Main,
  Course,
  Module,
  Lesson,
  AskQuestion,
  Privacy,
  Terms,
  FinalCongratulation,
};
