import { createAction } from "redux-actions";

export const UPDATE_ARTICLE_LOG = "UPDATE_ARTICLE_LOG";
export const UPDATE_ARTICLE_LOG_SUCCESS = "UPDATE_ARTICLE_LOG_SUCCESS";
export const UPDATE_ARTICLE_LOG_FAILURE = "UPDATE_ARTICLE_LOG_FAILURE";

export const UPDATE_VIDEO_LOG = "UPDATE_VIDEO_LOG";
export const UPDATE_VIDEO_LOG_SUCCESS = "UPDATE_VIDEO_LOG_SUCCESS";
export const UPDATE_VIDEO_LOG_FAILURE = "UPDATE_VIDEO_LOG_FAILURE";

export const updateArticleLogsAction = createAction(UPDATE_ARTICLE_LOG);
export const updateVideoLogsAction = createAction(UPDATE_VIDEO_LOG);
