import React from "react";
import { useSelector } from "react-redux";

import { courseProgressSelector } from "services/course/course.selectors";
import Styled from "../Course.Styled";

const CourseProgress = React.memo(() => {
  const progress = useSelector(courseProgressSelector);
  const percent = 100 / progress?.total;
  const progressPercent = percent * progress?.finishedCount;
  return (
    <Styled.ProgressWrap>
      <Styled.ProgressInfo>
        <div>{Math.round(progressPercent)}%</div>
        <div>
          {progress?.finishedCount} of {progress?.total} Completed
        </div>
      </Styled.ProgressInfo>
      <Styled.ProgressLine>
        <Styled.ProgressLineFill right={100 - progressPercent} />
      </Styled.ProgressLine>
    </Styled.ProgressWrap>
  );
});

export default CourseProgress;
