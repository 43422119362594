import styled from "styled-components";
import { Input as AntdInput } from "antd";

const TextArea = styled(AntdInput.TextArea)`
  margin-bottom: 16px;
  &&& {
    padding: 12px;
    min-height: 120px;
    border-radius: 7px;
    &:focus,
    &:hover {
      border-color: var(--blue);
    }
    &:focus {
      box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
    }
  }
`;

export default TextArea;
