import { call, put, select, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";
import isEmpty from "lodash/isEmpty";

import * as profileApi from "./profile.api";
import * as profileActions from "./profile.actions";
import * as profileSelectors from "./profile.selectors";
import {
  finishLoaderAction,
  startLoaderAction,
} from "services/loader/loader.action";
import openNotification, { prepareErrorMessages } from "utils/notifications";

function* getProfileDataOnLocationChangeData() {
  try {
    const profile = yield select(profileSelectors.profileSelector);
    const storage = localStorage.getItem("storageTyp");
    const token = window[storage]?.jwt;

    if (isEmpty(profile) && !!token) {
      yield put({ type: profileActions.GET_PROFILE_DATA });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchProfileData() {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(profileApi.getProfileData);

    yield put({
      type: profileActions.GET_PROFILE_DATA_SUCCESS,
      payload: response.data,
    });
  } catch ({ response }) {
    yield put({ type: profileActions.GET_PROFILE_DATA_FAILURE });
  } finally {
    yield put(finishLoaderAction());
  }
}

function* updateProfile({ payload: data }) {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(profileApi.updateProfileRequest, data);
    yield put({
      type: profileActions.UPDATE_PROFILE_SUCCESS,
      payload: response.data,
    });
    yield call(openNotification, {
      type: "success",
      message: "Success",
      description: "Profile successfully updated",
    });
  } catch ({ response }) {
    yield put({ type: profileActions.UPDATE_PROFILE_FAILURE });
    yield call(openNotification, {
      type: "error",
      message: "Error",
      description: prepareErrorMessages(response.data),
    });
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* profileSaga() {
  yield takeEvery(LOCATION_CHANGE, getProfileDataOnLocationChangeData);
  yield takeEvery(profileActions.GET_PROFILE_DATA, fetchProfileData);
  yield takeEvery(profileActions.UPDATE_PROFILE, updateProfile);
}
