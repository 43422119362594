import styled from "styled-components";
import { Link } from "react-router-dom";

import media from "constants/media";

const Header = styled.div`
  padding: 32px 56px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  z-index: 2;
  ${media.s} {
    padding: 32px;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const HeaderProfile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ headerWhite }) => (headerWhite ? "#fff" : "var(--dark)")};
  .anticon {
    cursor: pointer;
    height: 15px !important;
  }
`;

const HeaderAvatar = styled.div`
  border: 2px solid #d6dee1;
  width: 40px;
  height: 40px;
  border-radius: 40%;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  ${media.s} {
    display: none;
  }
`;

const HeaderInfo = styled.div`
  padding: 0 10px;
`;

const HeaderLogo = styled(Link)`
  img {
    max-width: 125px;
    ${media.s} {
      max-width: 60px;
    }
  }
`;

const HeaderName = styled.div`
  font-size: 16px;
`;

const HeaderEmail = styled.div`
  font-size: 12px;
`;

export default {
  Header,
  HeaderLogo,
  HeaderRow,
  HeaderProfile,
  HeaderAvatar,
  HeaderInfo,
  HeaderName,
  HeaderEmail,
};
