export default {
  modals: null,
  loader: false,
  appInfo: null,
  files: null,
  profile: null,
  course: {
    data: null,
    progress: null,
    quizResults: null,
    feedback: null,
  },
  lesson: null,
};
