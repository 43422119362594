import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import media from "constants/media";

const Card = styled.div`
  padding: 25px 0 60px;
  background: #fff;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  ${media.s} {
    padding: 20px 0;
  }
`;

const Title = styled.div`
  font-size: 48px;
  margin-top: 15px;
  margin-bottom: 25px;
  font-weight: 600;
  ${media.l} {
    font-size: 32px;
    margin-bottom: 18px;
  }
`;

const ProgressWrap = styled.div`
  padding: 0 70px 23px;
  border-bottom: 1px solid var(--lightBlue);
  ${media.m} {
    padding: 15px;
  }
`;

const ProgressInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--grey);
  padding: 0 0 5px;
`;

const ProgressLine = styled.div`
  position: relative;
  background: var(--lightBlue);
  border-radius: 10px;
  height: 6px;
`;

const ProgressLineFill = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: ${({ right }) => `${right}%`};
  background: var(--orange);
  border-radius: 10px;
`;

const CourseList = styled.div`
  padding: 48px 24px 0;
  ${media.m} {
    padding: 15px;
  }
  ${media.s} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
`;

const ModuleItem = styled(Link)`
  position: relative;
  display: inline-block;
  margin: 0 40px 64px;
  vertical-align: top;
  cursor: pointer;
  font-size: 12px;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
    `}
  img {
    display: block;
    max-width: 95px;
  }
`;

const ModuleCount = styled.div`
  color: var(--grey);
  padding-left: 8px;
`;

const ModuleName = styled.div`
  font-size: 14px;
  padding-left: 8px;
  font-weight: 600;
  color: ${({ isCompleted }) =>
    `${isCompleted ? `var(--green)` : `var(--dark)`}`};
`;

const ModuleComplete = styled.div`
  position: absolute;
  left: 27px;
  top: 42px;
  padding: 2px 8px;
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
  background: var(--green);
  border: 1px solid #fff;
  display: inline-block;
  .ant-icon {
    margin-right: 6px;
  }
`;

export default {
  Card,
  Title,
  ProgressWrap,
  ProgressInfo,
  ProgressLine,
  ProgressLineFill,
  CourseList,
  ModuleItem,
  ModuleCount,
  ModuleName,
  ModuleComplete,
};
