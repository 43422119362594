import instance from "../root.api";

export const getLessonRequest = (id) =>
  instance.get(`lessons/${id}`).then((response) => ({ response }));

export const submitLessonUploadsRequest = ({ id, uploads }) =>
  instance
    .post(`lessons/${id}/user-uploads`, { uploads })
    .then((response) => ({ response }));

export const getLessonUploadsRequest = (id) =>
  instance.get(`lessons/${id}/user-uploads`).then((response) => ({ response }));
