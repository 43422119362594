import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import {
  FileOutlined,
  CloseOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import isEmpty from "lodash/isEmpty";

import { uploadFileAction } from "services/filesUpload/filesUpload.actions";
import Styled from "../Lesson.Styled";
import { lessonFilesSelector } from "services/filesUpload/filesUpload.selectors";
import { submitLessonUploadsAction } from "services/lesson/lesson.actions";
import { courseProgressSelector } from "services/course/course.selectors";
import { updateProgressAction } from "services/course/course.actions";

const LessonUpload = ({ lesson }) => {
  const dispatch = useDispatch();
  const lessonUploadedFiles = useSelector(lessonFilesSelector);
  const progress = useSelector(courseProgressSelector);

  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles([
      ...(lessonUploadedFiles ? lessonUploadedFiles : []),
      ...(lesson?.uploads ? lesson.uploads : []),
    ]);
  }, [lessonUploadedFiles, lesson.uploads]);

  useEffect(() => {
    submitUploads();
  }, [files]);

  const updateProgress = () => {
    !progress?.finishedLessons?.includes(lesson?.id) &&
      dispatch(updateProgressAction(lesson.id));
  };

  const submitUploads = () => {
    if (isEmpty(files)) return;
    const uploads = files.map(({ id }) => id);
    updateProgress();
    dispatch(submitLessonUploadsAction({ uploads, id: lesson?.id }));
  };

  const onRemove = (id) => {
    const newFiles = files.filter(
      (file) => parseInt(file?.id) !== parseInt(id)
    );
    setFiles(newFiles);
  };

  return (
    <>
      <ReactMarkdown>{lesson?.Description}</ReactMarkdown>
      <Styled.Upload
        customRequest={({ file }) =>
          dispatch(uploadFileAction({ file, field: "lesson" }))
        }
        showUploadList={false}
      >
        <Styled.UploadWrapper>
          <Styled.UploadInner>
            <span>Drag & rop or click to browse</span>
            <DownloadOutlined />
          </Styled.UploadInner>
        </Styled.UploadWrapper>
      </Styled.Upload>

      <Styled.FilesList>
        {files.map(({ name, id }) => (
          <Styled.FileItem key={id}>
            <div>
              <FileOutlined /> <span>{name}</span>
            </div>
            <div>
              <CloseOutlined onClick={() => onRemove(id)} />
            </div>
          </Styled.FileItem>
        ))}
      </Styled.FilesList>
    </>
  );
};

export default LessonUpload;
