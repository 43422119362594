import React from "react";
import { Switch } from "react-router";

import { urlLocations } from "./urlLocations";
import RouteWrapper from "./RouterWrapper";
import MainLayout from "layouts/Main/Main";
import AuthorizedLayout from "layouts/AuthorizedLayout/AuthorizedLayout";
import Containers from "containers";

export default () => (
  <Switch>
    <RouteWrapper
      exact
      path={urlLocations.login}
      layout={MainLayout}
      component={Containers.Login}
    />
    <RouteWrapper
      exact
      path={urlLocations.main}
      layout={AuthorizedLayout}
      component={Containers.Main}
      headerWhite
    />
    <RouteWrapper
      exact
      path={urlLocations.course}
      layout={AuthorizedLayout}
      component={Containers.Course}
    />
    <RouteWrapper
      exact
      path={urlLocations.moduleInfo}
      layout={AuthorizedLayout}
      component={Containers.Module}
    />
    <RouteWrapper
      exact
      path={urlLocations.lessonInfo}
      layout={AuthorizedLayout}
      component={Containers.Lesson}
    />
    <RouteWrapper
      exact
      path={urlLocations.askQuestion}
      layout={AuthorizedLayout}
      component={Containers.AskQuestion}
    />
    <RouteWrapper
      exact
      path={urlLocations.privacy}
      layout={AuthorizedLayout}
      component={Containers.Privacy}
    />
    <RouteWrapper
      exact
      path={urlLocations.terms}
      layout={AuthorizedLayout}
      component={Containers.Terms}
    />
    <RouteWrapper
      exact
      path={urlLocations.finalCongratulation}
      layout={AuthorizedLayout}
      component={Containers.FinalCongratulation}
    />
  </Switch>
);
