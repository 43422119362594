import React from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";

import "antd/dist/antd.css";
import "./index.css";

import { history } from "routes/urlLocations";
import Routes from "routes/routes";
import store from "store";
import Loader from "components/Loader/Loader";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Loader>
        <ScrollToTop />
        <Routes />
      </Loader>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
