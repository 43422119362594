import styled from "styled-components";
import media from "constants/media";

const Title = styled.div`
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 80px;
  ${media.l} {
    font-size: 32px;
    margin-bottom: 40px;
  }
`;

export default {
  Title,
};
