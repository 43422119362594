import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Styled from "../Lesson.Styled";
import { courseQuizResultSelector } from "services/course/course.selectors";

const LessonQuiz = React.memo(({ lesson, onQuizChange }) => {
  const [answers, setAnswer] = useState({});
  const results = useSelector(courseQuizResultSelector);

  useEffect(() => {
    onQuizChange(answers);
  }, [answers]);

  return (
    <>
      {lesson.material?.[0].questions?.map((question, index) => (
        <Styled.QuizItem key={question.id}>
          <Styled.QuizQuestion>
            {index + 1}. {question.title}
          </Styled.QuizQuestion>

          {question.type === "open" ? (
            <Styled.TextArea
              onChange={(event) => {
                setAnswer({
                  ...answers,
                  [question.id]: event.target.value,
                });
              }}
            />
          ) : (
            <Styled.QuizOptions>
              {question.options.map(({ option, id }) => (
                <Styled.QuizOption
                  isSelected={answers[question.id] === id}
                  isCorrect={
                    results && answers[question.id] === id
                      ? results?.answersDetails?.[question?.id]?.isCorrect
                      : undefined
                  }
                  correctAnswer={
                    results &&
                    results?.answersDetails?.[question?.id]?.correctOptionID ===
                      id &&
                    !results?.answersDetails?.[question?.id]?.isCorrect
                      ? true
                      : undefined
                  }
                  onClick={() => {
                    setAnswer({
                      ...answers,
                      [question.id]: id,
                    });
                  }}
                  key={id}
                  isDisabled={!!results}
                >
                  {option}
                </Styled.QuizOption>
              ))}
            </Styled.QuizOptions>
          )}
          {results?.answersDetails?.[question?.id]?.correctAnswerComments && (
            <Styled.QuizAnswer>
              <Styled.QuizAnswerTitle>
                {results?.answersDetails?.[question?.id].isCorrect ? (
                  <span style={{ color: "#229ea2" }}>
                    This answer is correct.{" "}
                  </span>
                ) : (
                  <>
                    <span style={{ color: "#ff5b51" }}>
                      Answer is incorrect.{" "}
                    </span>
                    {`Correct
                    answer is "${String.fromCharCode(
                      question.options.findIndex(
                        (o) =>
                          parseInt(o?.id) ===
                          parseInt(
                            results?.answersDetails?.[question?.id]
                              .correctOptionID
                          )
                      ) + 65
                    )}
                    "`}
                  </>
                )}
              </Styled.QuizAnswerTitle>
              {results?.answersDetails?.[question?.id]?.correctAnswerComments}
            </Styled.QuizAnswer>
          )}
        </Styled.QuizItem>
      ))}
    </>
  );
});

export default LessonQuiz;
