import React from "react";
import { reduxForm } from "redux-form";
import { RightOutlined, UserOutlined, LockOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

import InputField from "components/InputField/InputField";
import Button from "components/Button/Button";
import { loginAction } from "services/login/login.action";
import { setModalStatusAction } from "services/modals/modals.action";
import { MODALS_ID } from "constants/modals";

const LoginForm = React.memo(({ handleSubmit, setRef }) => {
  const dispatch = useDispatch();
  return (
    <form onSubmit={handleSubmit}>
      <InputField
        prefix={<UserOutlined />}
        name="identifier"
        placeholder="Your email"
        type="email"
      />
      <InputField
        prefix={<LockOutlined />}
        name="password"
        placeholder="Your password"
        type="password"
      />
      <Button
        onClick={() =>
          dispatch(setModalStatusAction(MODALS_ID.DISCLAIMER_MODAL))
        }
      >
        Login <RightOutlined />
      </Button>
      <button
        ref={(r) => setRef(r)}
        htmlType="submit"
        style={{ visibility: "hidden" }}
      />
    </form>
  );
});

export default reduxForm({
  form: "loginForm",
  onSubmit: (value, dispatch) => dispatch(loginAction(value)),
})(LoginForm);
