import styled from "styled-components";
import media from "constants/media";

const Page = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px;
  min-height: 100vh;
  background: var(--backgroudGrey);
  align-items: stretch;
  ${media.m} {
    flex-direction: column;
  }
`;

const Aside = styled.div`
  display: flex;
  overflow: hidden;
  object-fit: cover;
  height: 100%;
  justify-content: center;
  ${media.m} {
    max-height: 400px;
    overflow: hidden;
  }
`;

const AsideImg = styled.img`
  border-radius: 5px;
  max-height: 100%;
  ${media.m} {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  flex: 0 60%;
  flex-direction: column;
  padding: 70px 10%;
  align-self: center;
  ${media.xl} {
    padding: 30px;
    flex: 0 70%;
  }
  ${media.m} {
    padding: 20px 0;
  }
`;

export default {
  Page,
  Aside,
  Content,
  AsideImg,
};
