import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";

import Styled from "./Header.Styled";
import Logo from "assets/img/Logo.png";
import { urlLocations } from "routes/urlLocations";
import { profileSelector } from "services/profile/profile.selectors";
import Avatar from "assets/img/profile.jpeg";
import { logoutAction } from "services/logout/logout.action";

const renderMenu = (logout) => (
  <Menu>
    <Menu.Item onClick={logout}>Logout</Menu.Item>
  </Menu>
);

const Header = React.memo(({ headerWhite }) => {
  const profile = useSelector(profileSelector);
  const dispatch = useDispatch();
  const logout = () => dispatch(logoutAction());

  return (
    <Styled.Header>
      <Styled.HeaderRow>
        <Styled.HeaderLogo to={urlLocations.main}>
          <img src={Logo} alt="" />
        </Styled.HeaderLogo>
        <Styled.HeaderProfile headerWhite={headerWhite}>
          <Styled.HeaderAvatar>
            <img src={Avatar} alt="" />
          </Styled.HeaderAvatar>
          <Styled.HeaderInfo>
            <Styled.HeaderName>
              {profile?.firstName} {profile?.lastName}
            </Styled.HeaderName>
            <Styled.HeaderEmail>{profile?.email}</Styled.HeaderEmail>
          </Styled.HeaderInfo>
          <Dropdown placement="bottomRight" overlay={() => renderMenu(logout)}>
            <DownOutlined />
          </Dropdown>
        </Styled.HeaderProfile>
      </Styled.HeaderRow>
    </Styled.Header>
  );
});

export default Header;
