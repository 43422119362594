import React from "react";

import ModalWrapper from "components/ModalWrapper/ModalWrapper";
import { MODALS_ID } from "constants/modals";
import Button from "components/Button/Button";

const LoginDisclaimerContent = ({ onSubmit }) => {
  return (
    <div>
      <h1>Disclaimer</h1>
      <p>
        This website is strictly meant for Healthcare Professionals
        (HCPs) only. Accessing and/or dissemination of this information beyond
        HCPs is restricted and not allowed. If you are not the intended
        recipient, you should delete this message and any disclosure, copying,
        or distribution of this message, or the taking of any action based on
        it, by you is strictly prohibited.
      </p>
      <p>
        <b>Copyright © 2021 Pfizer Inc. All rights reserved</b>
      </p>
      <Button onClick={onSubmit}>Ok</Button>
    </div>
  );
};

const LoginDisclaimer = ({ onSubmit }) => {
  return (
    <ModalWrapper
      modalId={MODALS_ID.DISCLAIMER_MODAL}
      component={LoginDisclaimerContent}
      onSubmit={onSubmit}
    />
  );
};

export default LoginDisclaimer;
