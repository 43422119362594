import styled from "styled-components";
import { Link } from "react-router-dom";

import media from "constants/media";

const Footer = styled.div`
  padding: 35px;
  border-top: 1px solid #d6dee1;
  ${media.l} {
    padding: 15px 10px;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  max-width: 1160px;
  margin: 0 auto;
  flex-direction: row;
  justify-content: space-between;
  ${media.s} {
    display: block;
  }
`;

const FooterLogo = styled(Link)`
  img {
    max-width: 90px;
    ${media.l} {
      max-width: 50px;
    }
  }
  ${media.s} {
    margin-bottom: 15px;
  }
`;

const FooterNavItem = styled(Link)`
  padding: 0 15px;
  line-height: 1;
  color: var(--dark);
  &:hover {
    color: var(--grey);
  }
  &:first-child {
    border-right: 1px solid var(--grey);
  }
  ${media.l} {
    padding: 0 10px;
  }
  ${media.s} {
    &:first-child {
      border-right: none;
    }
    padding: 10px 0;
  }
`;

const FooterNavigation = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  ${media.l} {
    padding-left: 15px;
  }
  ${media.s} {
    padding-left: 0;
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${media.s} {
    flex-direction: column;
    padding: 10px;
  }
`;

const CopyRight = styled.div`
  color: var(--grey);
  padding-left: 30px 
`;

const SocialNetworks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SocialNetworkItem = styled.a`
  margin-left: 10px;
  img {
    max-width: 52px;
  }
`;

export default {
  Footer,
  FooterContainer,
  FooterLogo,
  FooterNavigation,
  FooterNavItem,
  Col,
  CopyRight,
  SocialNetworks,
  SocialNetworkItem,
};
