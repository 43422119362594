import {takeEvery} from "redux-saga/effects";
import {LOGOUT} from "./logout.action";

import {history, urlLocations} from "../../routes/urlLocations";
import instance from "../root.api";
import {STORAGE_TYPE} from "constants/constants";

function* logout() {
    try {
        const storage =
            localStorage.getItem("storageTyp") || STORAGE_TYPE.SESSION_STORAGE;
        yield window[storage].clear();
        yield (instance.defaults.headers.common["Authorization"] = "");
        yield history.push(urlLocations.login);
    } catch (error) {
        console.log(error);
    }
}

export default function* logoutSaga() {
    yield takeEvery(LOGOUT, logout);
}
