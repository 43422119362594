import React from "react";

import StyledInput from "./Input.Styled";

const Input = ({ meta, className, label, value, ...props }) => {
  return (
    <StyledInput autoComplete="off" value={value} label={label} {...props} />
  );
};

export default Input;
