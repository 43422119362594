import React from "react";
import { LockOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import Button from "components/Button/Button";
import { MODALS_ID } from "constants/modals";
import ModalWrapper from "components/ModalWrapper/ModalWrapper";
import Styled from "./Course.Modal.Styled";
import { pathBuilder } from "routes/urlLocations";

const CourseModalContent = ({ lessonId }) => {
  return (
    <Styled.Content>
      <Styled.Wrap>
        <Styled.Icon>
          <LockOutlined />
        </Styled.Icon>
        <Styled.Description>
          Please complete the previous module & take the quiz to unlock the next module.
        </Styled.Description>
        <Link component={Button} to={pathBuilder.lesson(lessonId)}>
          Go to lesson
        </Link>
      </Styled.Wrap>
    </Styled.Content>
  );
};

const CourseModal = ({ lessonId }) => {
  return (
    <ModalWrapper
      component={CourseModalContent}
      modalId={MODALS_ID.BLOCKED_MODULE_MODAL}
      lessonId={lessonId}
    />
  );
};

export default CourseModal;
