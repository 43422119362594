import React from "react";
import ReactMarkdown from "react-markdown";
import { DownloadOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

import Styled from "../Lesson.Styled";
import { updateArticleLogsAction } from "services/logs/logs.actions";

const LessonArticle = React.memo(({ lesson }) => {
  const materail = lesson.material?.[0];
  const dispatch = useDispatch();
  return (
    <Styled.Article>
      <Styled.ArticlePreview>
        <img src={materail.thumbnail?.url} alt="" />
      </Styled.ArticlePreview>
      <Styled.ArticleMaterial>
        <Styled.ArticleDesc>
          <ReactMarkdown>{lesson?.Description}</ReactMarkdown>
        </Styled.ArticleDesc>
        <Styled.ArticleDownload
          href={materail?.article?.url}
          target={"__blank"}
          onClick={() => dispatch(updateArticleLogsAction(lesson?.id))}
        >
          Download
          <DownloadOutlined />
        </Styled.ArticleDownload>
      </Styled.ArticleMaterial>
    </Styled.Article>
  );
});

export default LessonArticle;
