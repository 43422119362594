import React from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

import {
  courseProgressSelector,
  courseSelector,
  moduleByIndexSelector,
} from "services/course/course.selectors";
import { urlLocations } from "routes/urlLocations";
import Styled from "./Module.Styled";
import Button from "components/Button/Button";
import ModuleLesson from "./Module.Lesson/Module.Lesson";
import { getListOfLessonsId } from "utils/lessons";
import ModuleModal from "containers/Module/Module.Modal/Module.Modal";
import { setModalStatusAction } from "services/modals/modals.action";
import { MODALS_ID } from "constants/modals";

const Module = React.memo(() => {
  const { index } = useParams();
  const module = useSelector((state) => moduleByIndexSelector(state, index));
  const course = useSelector(courseSelector);
  const progress = useSelector(courseProgressSelector);
  const lessonsIDs = getListOfLessonsId(course?.modules) || [];
  const dispatch = useDispatch();
  const lastFinishedLesson =
    progress?.finishedLessons?.[progress?.finishedLessons?.length - 1];
  const lastFinishedIndex = lessonsIDs?.findIndex(
    (id) => id === lastFinishedLesson
  );
  const lastActiveLessonId = lessonsIDs?.[lastFinishedIndex + 1];

  const isLessonDisabled = (lesson) => {
    if (lessonsIDs?.[0] === lesson?.id) return false;
    const currentIndex = lessonsIDs?.findIndex((id) => id === lesson?.id);
    const prevLessonID = lessonsIDs?.[currentIndex - 1];
    if (progress?.finishedLessons?.includes(prevLessonID)) return false;
    if (!progress?.finishedLessons?.includes(lesson?.id)) return true;
    return false;
  };

  const onLessonClick = (event, lesson) => {
    if (isLessonDisabled(lesson)) {
      event.preventDefault();
      dispatch(setModalStatusAction(MODALS_ID.BLOCKED_LESSON_MODAL));
    }
  };

  return (
    <>
      <Link simplebutton="true" component={Button} to={urlLocations.course}>
        <LeftOutlined /> Back to all modules
      </Link>
      <Styled.Card>
        <Styled.CardHeader>
          <Styled.Title>
            {/*<Styled.ModuleCount>*/}
            {/*  Module #{parseInt(index) + 1}*/}
            {/*</Styled.ModuleCount>{" "}*/}
            {module?.title}
          </Styled.Title>
        </Styled.CardHeader>
        <Styled.List>
          {module?.lessons?.map((lesson, lessonIndex) => (
            <ModuleLesson
              key={lesson?.id}
              lesson={lesson}
              lessonIndex={lessonIndex}
              isDisabled={isLessonDisabled(lesson)}
              onClick={(event) => onLessonClick(event, lesson)}
              isCompleted={progress?.finishedLessons?.includes(lesson?.id)}
            />
          ))}
        </Styled.List>
      </Styled.Card>
      <ModuleModal lessonId={lastActiveLessonId} />
    </>
  );
});

export default Module;
