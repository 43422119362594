import React from "react";

import { history, urlLocations } from "routes/urlLocations";
import { STORAGE_TYPE } from "constants/constants";
import MainImg from "assets/img/Main.jpg";

import Styled from "./Main.Styled";

const Main = ({ children }) => {
  React.useEffect(() => {
    const storage =
      localStorage.getItem("storageTyp") || STORAGE_TYPE.LOCAL_STORAGE;
    const jwt = window[storage]?.getItem("jwt");
    jwt && history.push(urlLocations.main);
  }, []);

  return (
    <Styled.Page>
      <Styled.Aside>
        <Styled.AsideImg src={MainImg} />
      </Styled.Aside>
      <Styled.Content>{children}</Styled.Content>
    </Styled.Page>
  );
};

export default Main;
