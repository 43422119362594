import { call, put, takeEvery } from "redux-saga/effects";

import {
  finishLoaderAction,
  startLoaderAction,
} from "services/loader/loader.action";
import {
  ASK_QUESTION,
  ASK_QUESTION_FAILURE,
  ASK_QUESTION_SUCCESS,
} from "./askQuestion.actions";
import { history, pathBuilder } from "routes/urlLocations";
import { askQuestionRequest } from "./askQuestion.api";
import openNotification from "utils/notifications";

function* askQuestion({ payload }) {
  try {
    yield put(startLoaderAction());
    yield call(askQuestionRequest, payload);
    yield put({
      type: ASK_QUESTION_SUCCESS,
    });
    yield history.push(pathBuilder.lesson(payload.lesson));
    yield call(openNotification, {
      type: "success",
      message: "Success",
      description: "Question was successfully sent",
    });
  } catch (error) {
    yield put({ type: ASK_QUESTION_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* askQuestionSaga() {
  yield takeEvery(ASK_QUESTION, askQuestion);
}
