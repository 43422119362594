import React from "react";

import StyledTextArea from "./TextArea.Styled";

const TextArea = ({ meta, className, label, value, ...props }) => {
  return (
    <StyledTextArea
      className={className}
      autoComplete="off"
      value={value}
      label={label}
      {...props}
    />
  );
};

export default TextArea;
