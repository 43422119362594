import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  courseProgressSelector,
  courseSelector,
} from "services/course/course.selectors";
import CourseModule from "./Course.Module/Course.Module";
import CourseProgress from "./Course.Progress/Course.Progress";
import Styled from "./Course.Styled";
import { getListOfLessonsId } from "utils/lessons";
import CourseModal from "./Course.Modal/Course.Modal";
import { setModalStatusAction } from "services/modals/modals.action";
import { MODALS_ID } from "constants/modals";
import Button from "components/Button/Button";
import {urlLocations} from "routes/urlLocations";
import {LeftOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

const Course = React.memo(() => {
  const course = useSelector(courseSelector);
  const progress = useSelector(courseProgressSelector);
  const lessonsIDs = getListOfLessonsId(course?.modules) || [];
  const dispatch = useDispatch();
  const lastFinishedLesson =
    progress?.finishedLessons?.[progress?.finishedLessons?.length - 1];
  const lastFinishedIndex = lessonsIDs?.findIndex(
    (id) => id === lastFinishedLesson
  );
  const lastActiveLessonId = lessonsIDs?.[lastFinishedIndex + 1];

  const isModuleDisabled = (module, index) => {
    if (index === 0) return false;
    const firstLessonId = module?.lessons?.[0]?.id;
    const firstLessonIndex = lessonsIDs?.findIndex(
      (id) => id === firstLessonId
    );
    const prevLessonID = lessonsIDs?.[firstLessonIndex - 1];
    if (progress?.finishedLessons?.includes(prevLessonID)) return false;
    return true;
  };

  const isModuleComplete = (module) => {
    return module.lessons.every((l) =>
      progress?.finishedLessons?.includes(l.id)
    );
  };

  const onModuleClick = (event, module, index) => {
    if (isModuleDisabled(module, index)) {
      event.preventDefault();
      dispatch(setModalStatusAction(MODALS_ID.BLOCKED_MODULE_MODAL));
    }
  };

  return (
    <>
      <Link simplebutton="true" component={Button} to={urlLocations.main}>
        <LeftOutlined /> Back to course Intro
      </Link>
      <Styled.Title>{course?.title}</Styled.Title>
      <Styled.Card>
        <CourseProgress />
        <Styled.CourseList>
          {course?.modules?.map((module, index) => (
            <CourseModule
              isDisabled={isModuleDisabled(module, index)}
              key={module?.id}
              module={module}
              index={index}
              isCompleted={isModuleComplete(module)}
              onClick={(event) => {
                onModuleClick(event, module, index);
              }}
            />
          ))}
        </Styled.CourseList>
      </Styled.Card>
      <CourseModal lessonId={lastActiveLessonId} />
    </>
  );
});

export default Course;
