import React from "react";
import { CheckOutlined } from "@ant-design/icons";

import { pathBuilder } from "routes/urlLocations";
import Folder from "assets/img/Folder.png";
import Styled from "../Course.Styled";

const CourseModule = React.memo(
  ({ index, module, isDisabled, isCompleted, onClick }) => {
    return (
      <Styled.ModuleItem
        onClick={onClick}
        isDisabled={isDisabled}
        to={pathBuilder.module(index)}
      >
        <img src={Folder} alt="" />
        <div>
          {/*<Styled.ModuleCount>Module {`#${index + 1}`}</Styled.ModuleCount>*/}
          <Styled.ModuleName isCompleted={isCompleted}>
            {module?.title}
          </Styled.ModuleName>
          {isCompleted && (
            <Styled.ModuleComplete>
              <CheckOutlined />
              Completed
            </Styled.ModuleComplete>
          )}
        </div>
      </Styled.ModuleItem>
    );
  }
);

export default CourseModule;
