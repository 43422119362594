import styled from "styled-components";
import { Input as AntdInput } from "antd";

const Input = styled(AntdInput)`
  margin-bottom: 16px;
  &&& {
    height: 52px;
    padding: 12px;
    border-radius: 7px;
    background: #fff;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    &:focus,
    &:hover {
      border-color: var(--blue);
    }
    &:focus {
      box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
    }
  }
`;

export default Input;
