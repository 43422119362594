import React, { useState } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";

import Styled from "./AskQuestion.Styled";
import Input from "components/Input/Input";
import TextArea from "components/TextArea/TextArea";
import Button from "components/Button/Button";
import { history } from "routes/urlLocations";
import { askQuestionActions } from "services/askQuestion/askQuestion.actions";

const AskQuestion = React.memo(() => {
  const { lessonId: lesson } = useParams();
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const handleChange = ({ target: { value } }, field) => {
    setData({
      ...data,
      [field]: value,
    });
  };

  return (
    <>
      <Button onClick={() => history.goBack()} simplebutton="true">
        <LeftOutlined /> Back to the lesson
      </Button>
      <Styled.Card>
        <Styled.CardHeader>
          <Styled.Title>Send question to the speaker Prof. Enver</Styled.Title>
        </Styled.CardHeader>
        <Styled.CardContent>
          <Input
            onChange={(event) => {
              handleChange(event, "subject");
            }}
            placeholder="Subject"
          />
          <TextArea
            onChange={(event) => {
              handleChange(event, "questionContent");
            }}
            placeholder="Question"
          />
          <Styled.ButtonRow>
            <Button
              onClick={() => dispatch(askQuestionActions({ ...data, lesson }))}
              disabled={!data?.subject || !data.questionContent}
            >
              Send message
            </Button>
          </Styled.ButtonRow>
        </Styled.CardContent>
      </Styled.Card>
    </>
  );
});

export default AskQuestion;
