import styled from "styled-components";
import MainBG from "assets/img/mainBG.jpg";

import media from "constants/media";

const Page = styled.div`
  padding: 160px 0;
  margin: -140px 0 0;
  width: 100vw;
  left: 50%;
  position: relative;
  margin-left: -50vw;
  background-image: url(${MainBG});
  background-size: 100% 100%;
`;

const Container = styled.div`
  max-width: 1160px;
  margin: 0 auto;
  ${media.xl} {
    max-width: 960px;
  }
`;

const IntroSection = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 70px;
  ${media.l} {
    padding: 20px;
  }
  ${media.m} {
    flex-direction: column;
  }
`;

const IntroTitle = styled.div`
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 10px;
  color: var(--blue);
  text-transform: uppercase;
  ${media.xl} {
    font-size: 32px;
  }
`;

const IntroInfo = styled.div`
  flex: 0 50%;
  padding-right: 50px;
  ${media.l} {
    padding-right: 50px;
  }
  ${media.l} {
    padding: 50px 0;
    max-width: 80%;
    margin: 0 auto;
  }
  ${media.s} {
    max-width: 100%;
  }
`;

const IntroDescription = styled.div`
  color: #fff;
`;

const IntroVideo = styled.div`
  flex: 0 50%;
  margin: auto 0;
  ${media.l} {
    max-width: 80%;
    margin: 0 auto;
  }
  ${media.s} {
    max-width: 100%;
  }
  video {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
`;

const About = styled.div`
  margin-bottom: -120px;
  padding-bottom: 30px;
`;

const Card = styled.div`
  position: relative;
  z-index: 1;
  max-width: 1160px;
  margin: 0 auto;
  padding: 30px 60px 40px;
  background: #fff;
  border-radius: 7px;
  transform: translateY(-170px);
  ${media.l} {
    padding: 20px 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  ${media.l} {
    flex-direction: column;
  }
`;

const CardCol = styled.div`
  flex: 0 50%;
  padding: 0 30px;
  ${media.l} {
    padding: 30px;
  }
`;

const AboutTitle = styled.div`
  font-size: 24px;
  margin-bottom: 16px;
  font-weight: 600;
`;

const AboutFooter = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 44px 30px 0;
`;

export default {
  Page,
  Container,
  IntroSection,
  IntroTitle,
  IntroInfo,
  IntroDescription,
  IntroVideo,
  About,
  Card,
  CardCol,
  AboutTitle,
  AboutFooter,
  Row,
};
