import instance from "../root.api";

export const courseRequest = () =>
  instance.get("/courses/me").then((response) => ({ response }));

export const courseProgressRequest = () =>
  instance.get("/user-progresses/me").then((response) => ({ response }));

export const updateCourseProgressRequest = (id) =>
  instance
    .post("/user-progresses/me", { lesson: id })
    .then((response) => ({ response }));

export const submitCourseQuizRequest = ({ id, answers }) =>
  instance
    .post(`/lessons/${id}/quiz-answer`, { answers })
    .then((response) => ({ response }));

export const submitFeedbackRequest = ({ id, answers }) =>
  instance
    .post(`/lessons/${id}/feedback-answer`, { answers })
    .then((response) => ({ response }));
