import { createAction } from "redux-actions";

export const GET_LESSON = "GET_LESSON";
export const GET_LESSON_SUCCESS = "GET_LESSON_SUCCESS";
export const GET_LESSON_FAILURE = "GET_LESSON_FAILURE";

export const SUBMIT_LESSON_UPLOADS = "SUBMIT_LESSON_UPLOADS";
export const SUBMIT_LESSON_UPLOADS_SUCCESS = "SUBMIT_LESSON_UPLOADS_SUCCESS";
export const SUBMIT_LESSON_UPLOADS_FAILURE = "SUBMIT_LESSON_UPLOADS_FAILURE";

export const GET_LESSON_UPLOADS = "GET_LESSON_UPLOADS";
export const GET_LESSON_UPLOADS_SUCCESS = "GET_LESSON_UPLOADS_SUCCESS";
export const GET_LESSON_UPLOADS_FAILURE = "GET_LESSON_UPLOADS_FAILURE";

export const submitLessonUploadsAction = createAction(SUBMIT_LESSON_UPLOADS);
