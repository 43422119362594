import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { connectRouter } from "connected-react-router";

import { LOGOUT } from "services/logout/logout.action";
import { history } from "routes/urlLocations";
import initialState from "store/initialState";
import modalsReducer from "services/modals/modals.reducer";
import loaderReducer from "services/loader/loader.reducer";
import appInfoReducer from "services/appInfo/appInfo.reducer";
import courseReducer from "services/course/course.reducer";
import lessonReducer from "services/lesson/lesson.reducer";
import uploadFilesReducer from "services/filesUpload/filesUpload.reducer";
import profileReducer from "services/profile/profile.reducer";

const appReducer = combineReducers({
  form: formReducer,
  modals: modalsReducer,
  loader: loaderReducer,
  appInfo: appInfoReducer,
  course: courseReducer,
  files: uploadFilesReducer,
  lesson: lessonReducer,
  profile: profileReducer,
  router: connectRouter(history),
});

const rootReducers = (state, action) => {
  if (action.type === LOGOUT) {
    state = initialState;
  }
  return appReducer(state, action);
};

export default rootReducers;
