import React from "react";

import FooterLogo from "assets/img/FooterLogo.png";
import Styled from "./Footer.Styled";
import moment from "moment";
import { urlLocations } from "routes/urlLocations";
import FBIcon from "assets/img/facebook.png";
import InstagramIcon from "assets/img/instagram.png";
import LinkedInIcon from "assets/img/linkedIn.png";
import PfizerLogo from "assets/img/pfizer-logo.png";

const Footer = React.memo(() => {
  return (
    <Styled.Footer>
      <Styled.FooterContainer>
        <Styled.Col>
          <Styled.FooterLogo to={urlLocations.main}>
            <img src={FooterLogo} alt="" />
          </Styled.FooterLogo>
          <Styled.FooterNavigation>
            <Styled.FooterNavItem to={urlLocations.privacy}>
              Privacy Policy
            </Styled.FooterNavItem>
            <Styled.FooterNavItem to={urlLocations.terms}>
              Contact Us{" "}
            </Styled.FooterNavItem>
          </Styled.FooterNavigation>
          <Styled.CopyRight>
            Copyright ©{moment(Date.now()).year()} Pfizer Inc. All rights reserved <span><img style={{width: '60px', paddingLeft: '10px'}} src={PfizerLogo}/></span>
            <br/>
            Pfizer Identification Number: <b>PP-ABV-BHR-0001</b>
          </Styled.CopyRight>
        </Styled.Col>
        {/*<Styled.SocialNetworks>*/}
        {/*  <Styled.SocialNetworkItem href={"#"} target="_blank">*/}
        {/*    <img src={FBIcon} alt="" />*/}
        {/*  </Styled.SocialNetworkItem>*/}
        {/*  <Styled.SocialNetworkItem href={"#"} target="_blank">*/}
        {/*    <img src={InstagramIcon} alt="" />*/}
        {/*  </Styled.SocialNetworkItem>*/}
        {/*  <Styled.SocialNetworkItem href={"#"} target="_blank">*/}
        {/*    <img src={LinkedInIcon} alt="" />*/}
        {/*  </Styled.SocialNetworkItem>*/}
        {/*</Styled.SocialNetworks>*/}
      </Styled.FooterContainer>
    </Styled.Footer>
  );
});

export default Footer;
