import React from "react";

import { pathBuilder } from "routes/urlLocations";
import Styled from "../Module.Styled";

import ArticleFolder from "assets/img/ArticleFolder.png";
import UploadFolder from "assets/img/UploadFolder.png";
import VideoFolder from "assets/img/VideoFolder.png";
import QuizFolder from "assets/img/QuizeFolder.png";
import { getListOfLessonsId } from "utils/lessons";
import { useSelector } from "react-redux";
import { courseSelector } from "services/course/course.selectors";
import { CheckOutlined } from "@ant-design/icons";

const TYPE_TO_ICON = {
  article: ArticleFolder,
  video: VideoFolder,
  quiz: QuizFolder,
  feedback: QuizFolder,
  upload: UploadFolder,
};

const ModuleLesson = React.memo(
  ({ lesson, isDisabled, isCompleted, onClick }) => {
    const course = useSelector(courseSelector);
    const lessonsIDs = getListOfLessonsId(course?.modules) || [];

    return (
      <Styled.ModuleLesson
        isDisabled={isDisabled}
        key={lesson?.id}
        to={pathBuilder.lesson(lesson?.id)}
        onClick={onClick}
      >
        {isCompleted && (
          <Styled.LessonComplete>
            <CheckOutlined />
            Completed
          </Styled.LessonComplete>
        )}
        <img src={TYPE_TO_ICON[lesson?.type]} alt="" />
        <Styled.LessonCount>
          Lesson #{lessonsIDs.findIndex((id) => id === lesson?.id) + 1}
        </Styled.LessonCount>
        <Styled.LessonName isCompleted={isCompleted}>
          {lesson?.title}
        </Styled.LessonName>
        <br />
      </Styled.ModuleLesson>
    );
  }
);

export default ModuleLesson;
