import { notification } from "antd";

export const prepareErrorMessages = ({ message, data } = {}) => {
  if (!message) return "Oops! Something went wrong!";
  if (Array.isArray(message)) {
    return message
      .map((m) =>
        m.messages?.map((m2) => {
          return m2?.message;
        })
      )
      .join("\n");
  } else {
    return message;
  }
};

const openNotification = ({
  message,
  description,
  type = "info",
  duration,
}) => {
  notification[type]({
    duration: duration ? duration : type === "error" ? 9999999999 : 4.5,
    message,
    description,
  });
};

export default openNotification;
