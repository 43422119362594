import styled, { css } from "styled-components";
import { Upload as AntUpload } from "antd";
import isUndefined from "lodash/isUndefined";

import TextArea from "components/TextArea/TextArea";
import media from "constants/media";

const Card = styled.div`
  padding: 25px 0 60px;
  background: #fff;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  margin-top: 25px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const LessonCount = styled.div`
  display: inline-block;
  color: var(--grey);
  margin-right: 15px;
`;

const CardHeader = styled.div`
  padding: 0 30px 25px;
  border-bottom: 1px solid #d6dee1;
`;

const CardContent = styled.div`
  padding-top: 40px;
  width: 70%;
  margin: 0 auto;
  ${media.l} {
    width: 85%;
  }
  ${media.m} {
    width: 95%;
  }
  video {
    max-width: 100%;
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  flex: 0 50%;
  margin-bottom: 15px;
  video {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
`;

const UploadWrapper = styled.div`
  border: 1px solid #d6dee1;
  border-radius: 7px;
  padding: 4px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.8;
  }
`;

const UploadInner = styled.div`
  border: 1px dashed #d6dee1;
  border-radius: 7px;
  text-align: center;
  padding: 24px 0;
  color: var(--green);
  opacity: 1;
  transition: opacity 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const Upload = styled(AntUpload)`
  &&& {
    display: block;
    .ant-upload {
      display: block;
    }
  }
`;

const FilesList = styled.div`
  padding: 15px 0;
`;

const FileItem = styled.div`
  padding: 10px 15px;
  margin: 15px 0;
  background: var(--backgroudGrey);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 7px;
  &&& {
    * {
      display: flex;
      flex-direction: row;
      align-self: center;
      align-items: center;
    }
    .anticon {
      margin-right: 10px;
      color: var(--grey);
    }
    .anticon-file {
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .anticon-close {
      cursor: pointer;
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
`;

const QuizItem = styled.div`
  margin-bottom: 40px;
`;

const QuizQuestion = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 12px;
`;

const QuizOptions = styled.ul`
  padding: 0;
  margin: 0;
  list-style: upper-latin inside;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 15px;
`;

const QuizOption = styled.li`
  padding: 12px 24px;
  border-radius: 7px;
  border: 1px solid var(--lightBlue);
  width: 45%;
  margin: 10px;
  cursor: pointer;
  ${media.l} {
    width: 45%;
  }
  ${media.m} {
    width: 43%;
  }
  ${media.s} {
    width: 100%;
  }
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      cursor: initial;
    `}
  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1px solid var(--green);
      background-color: var(--backgroudGrey);
    `}
  ${({ isCorrect }) => {
    if (isUndefined(isCorrect)) return "";

    return isCorrect
      ? css`
          border-color: var(--green);
          color: var(--green);
        `
      : css`
          border-color: #ff5b51;
          color: #ff5b51;
        `;
  }}
  ${({ correctAnswer }) => {
    if (isUndefined(correctAnswer)) return "";

    return (
      correctAnswer &&
      css`
        color: var(--green);
      `
    );
  }}
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    width: 47%;
    ${media.s} {
      width: 100%;
    }
  }
`;

const LessonFooter = styled.div`
  border-top: 1px solid #d6dee1;
`;

const LessonFooterInner = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  ${media.s} {
    flex-direction: column;
    & > * {
      margin: 10px 0 !important;
    }
  }
`;

const Article = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -15px;
  ${media.m} {
    flex-direction: column;
  }
`;

const ArticlePreview = styled.div`
  flex: 30%;
  padding: 0 15px;
  img {
    max-width: 100%;
  }
`;

const ArticleMaterial = styled.div`
  flex: auto;
  padding: 0 15px 30px;
`;

const ArticleDesc = styled.div`
  margin-bottom: 24px;
`;

const ArticleDownload = styled.a`
  display: block;
  color: var(--green);
  background: var(--backgroudGrey);
  text-decoration: none;
  border-radius: 7px;
  text-align: center;
  padding: 15px 0;
  .anticon {
    margin-left: 15px;
  }
  &:hover {
    color: var(--blue);
  }
`;

const QuizAnswer = styled.div`
  padding: 8px 16px;
  background: var(--backgroudGrey);
  border-radius: 7px;
`;

const QuizAnswerTitle = styled.div`
  font-weight: 600;
`;

export default {
  Article,
  ArticlePreview,
  ArticleMaterial,
  ArticleDesc,
  ArticleDownload,
  Card,
  QuizOptions,
  QuizOption,
  LessonCount,
  Title,
  CardHeader,
  CardContent,
  VideoWrapper,
  UploadWrapper,
  UploadInner,
  Upload,
  FileItem,
  FilesList,
  QuizItem,
  QuizQuestion,
  TextArea: StyledTextArea,
  LessonFooter,
  LessonFooterInner,
  QuizAnswer,
  QuizAnswerTitle,
};
