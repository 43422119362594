import { call, put, takeEvery } from "redux-saga/effects";

import {
  finishLoaderAction,
  startLoaderAction,
} from "services/loader/loader.action";

import {
  UPDATE_ARTICLE_LOG,
  UPDATE_ARTICLE_LOG_SUCCESS,
  UPDATE_ARTICLE_LOG_FAILURE,
  UPDATE_VIDEO_LOG,
  UPDATE_VIDEO_LOG_FAILURE,
  UPDATE_VIDEO_LOG_SUCCESS,
} from "./logs.actions";

import { GET_PROGRESS, UPDATE_PROGRESS } from "services/course/course.actions";

import { updateArticleLogRequest, updateVideoLogRequest } from "./logs.api";

function* updateArticle({ payload: id }) {
  try {
    yield put(startLoaderAction());
    yield call(updateArticleLogRequest, id);
    yield put({
      type: UPDATE_ARTICLE_LOG_SUCCESS,
    });
    yield put({
      type: UPDATE_PROGRESS,
      payload: id,
    });
  } catch (error) {
    yield put({ type: UPDATE_ARTICLE_LOG_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* updateVideoLog({ payload }) {
  try {
    yield put(startLoaderAction());
    yield call(updateVideoLogRequest, payload);
    yield put({
      type: UPDATE_VIDEO_LOG_SUCCESS,
    });
    yield put({
      type: UPDATE_PROGRESS,
      payload: payload?.lesson,
    });
  } catch (error) {
    yield put({ type: UPDATE_VIDEO_LOG_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* logsSaga() {
  yield takeEvery(UPDATE_ARTICLE_LOG, updateArticle);
  yield takeEvery(UPDATE_VIDEO_LOG, updateVideoLog);
}
