import React from "react";

const ControlWrapper = ({
  children,
  name,
  errors,
  error,
  customTouched,
  touched,
}) => (
  <>
    <div className="control-wrapper">
      {children}
      {errors?.[name] &&
        !customTouched &&
        errors?.[name].map((error) => (
          <div key={error}>
            <span key={error}>{error}</span>
          </div>
        ))}
      {error && touched && (
        <div key={error}>
          <span>{error}</span>
        </div>
      )}
    </div>
  </>
);

export default ControlWrapper;
