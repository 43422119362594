import React from "react";

import MainIntro from "./Main.Intro";
import MainAbout from "containers/Main/Main.About";

const Main = React.memo(() => {
  return (
    <>
      <MainIntro />
      <MainAbout />
    </>
  );
});

export default Main;
