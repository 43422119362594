import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";

import { isModalVisibleSelector } from "services/modals/modals.selectors";
import { setModalStatusAction } from "services/modals/modals.action";

const ModalWrapper = ({
  component: Component,
  footer = null,
  title = null,
  modalId,
  onClose,
  ...props
}) => {
  const dispatch = useDispatch();
  const isModalVisible = useSelector((state) =>
    isModalVisibleSelector(state, modalId)
  );
  return (
    <Modal
      centered
      destroyOnClose
      visible={isModalVisible}
      footer={footer}
      title={title}
      onCancel={() => {
        onClose && onClose();
        dispatch(setModalStatusAction(null));
      }}
      {...props}
    >
      <Component
        {...props}
        setModalStatus={(status) => dispatch(setModalStatusAction(status))}
      />
    </Modal>
  );
};

ModalWrapper.propTypes = {
  modalId: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  onClose: PropTypes.func,
};

export default ModalWrapper;
