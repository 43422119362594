import React from "react";
import {RightOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

import Styled from "./Main.Styled";
import Button from "components/Button/Button";
import {urlLocations} from "routes/urlLocations";
import ShortLogo from "assets/img/shortLogo.png";
import pfizerLogo from "assets/img/pfizer-logo.png";
import InseadLogo from "assets/img/logo_insead.png";

const MainAbout = React.memo(() => {
    return (
        <Styled.About>
            <Styled.Card>
                <Styled.Row>
                    <Styled.CardCol>
                        <Styled.AboutTitle>
                            About SCM Curriculum
                        </Styled.AboutTitle>
                        <p>We have indeed come a long way since maréchal de logis, the French military logistics officer
                            responsible for organizing all camp facilities for troops at war. Two key issues, however,
                            deserve further attention. First, activities within the realm of Supply Chain Management
                            (SCM) are typically viewed as cost centers whereby cost minimization remains the key focus
                            of most SCM streamlining efforts. To compound the problem, the gap between the performance
                            metrics typically used in the field (such as inventory turns, order fulfilment rates, order
                            turnaround times) and the financial indicators typically monitored by the upper management
                            and the investors (such as economic profit, ROI, RONA) remains wide. This course therefore
                            adopts a value-based management (VBM) perspective in which SCM is deployed not just for cost
                            minimization, but also for value creation and value capture. This broader perspective is a
                            pre-requisite for the development and successful deployment of disruptive new business
                            models.
                        </p>
                        <p>The second issue is concerned with governance in supply chains. Many business observers claim
                            that competition is no longer among companies, but among supply chains. This claim
                            implicitly assumes the existence of a harmonious supply chain whose members are all working
                            for the success of the overall network. Unfortunately, many misalignments exist in practice
                            among these members. This course will also focus on the deployment of adequate incentive
                            mechanisms to bolster such alignment in modern supply chains with no clear command and
                            control structures.
                        </p>
                        <p>Finally, similar to products and process technologies, supply chain solutions also have a
                            limited shelf life. As market characteristics, technological capabilities & competitive
                            landscape change and supply chain solutions become obsolete. In other words, all competitive
                            advantages in SCM are temporary. To ensure sustainability, this course will discuss the
                            capability to design & assemble assets, organizations, skill sets and competencies for a
                            series of competitive advantages, rather than a set of activities held together by low
                            transaction costs.
                        </p>
                    </Styled.CardCol>
                    <Styled.CardCol>
                        <Styled.AboutTitle>
                            About Pfizer<span><img style={{width: '90px', paddingLeft: '20px'}} src={pfizerLogo}/></span>
                        </Styled.AboutTitle>
                        <p>
                            <b>Breakthroughs That Change Patients’ Lives</b>
                            <br/>
                            At Pfizer, we apply science and our global resources to bring therapies to people that
                            extend and significantly improve their lives. We strive to set the standard for quality,
                            safety and value in the discovery, development and manufacture of health care products,
                            including innovative medicines and vaccines. Every day, Pfizer colleagues work across
                            developed and emerging markets to advance wellness, prevention, treatments and cures that
                            challenge the most feared diseases of our time. Consistent with our responsibility as one of
                            the world's premier innovative biopharmaceutical companies, we collaborate with health care
                            providers, governments and local communities to support and expand access to reliable,
                            affordable health care around the world. For more than 150 years, we have worked to make a
                            difference for all who rely on us. We routinely post information that may be important to
                            investors on our website at www.Pfizer.com
                        </p>
                        <Styled.AboutTitle>
                            About INSEAD<span><img style={{width: '60px', paddingLeft: '10px'}} src={InseadLogo}/></span>
                        </Styled.AboutTitle>
                        <p>INSEAD is a nonprofit graduate business school with locations in Europe (Fontainebleau,
                            France), Asia (Singapore), the Middle East (Abu Dhabi, UAE), and North America (San
                            Francisco, USA). INSEAD is one of the largest providers of business school executive
                            education in the world. Over 10,000 executives participate annually in INSEAD custom and
                            open programs. INSEAD partners with over 250 global companies every year to design and
                            deliver custom executive development programs. Their +165 Faculty are thought leaders in
                            their fields and INSEAD is one of the world leaders in academic research across all business
                            disciplines.
                        </p>
                    </Styled.CardCol>
                </Styled.Row>
                <Styled.AboutFooter>
                    <Link to={urlLocations.course} component={Button}>
                        Start Course
                        <RightOutlined/>
                    </Link>
                </Styled.AboutFooter>
            </Styled.Card>
        </Styled.About>
    );
});

export default MainAbout;
