import { createAction } from "redux-actions";

export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";

export const DELETE_FILE = "DELETE_FILE";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAILURE = "DELETE_FILE_FAILURE";

export const CLEAR_FILES = "CLEAR_FILES";
export const UPDATE_FILES = "UPDATE_FILES";

export const uploadFileAction = createAction(UPLOAD_FILE);
export const deleteFileAction = createAction(DELETE_FILE);
