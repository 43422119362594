import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import { lessonSelector } from "services/lesson/lesson.selectors";
import {
  submitCourseQuizAction,
  submitFeedbackAction,
} from "services/course/course.actions";
import Button from "components/Button/Button";
import { history, pathBuilder, urlLocations } from "routes/urlLocations";
import Styled from "./Lesson.Styled";
import LessonArticle from "./Lesson.Content/Lesson.Article";
import LessonVideo from "./Lesson.Content/Lesson.Video";
import LessonUpload from "./Lesson.Content/Lesson.Upload";
import LessonQuiz from "./Lesson.Content/Lesson.Quiz";
import {
  courseFeedbackResultSelector,
  courseProgressSelector,
  courseQuizResultSelector,
  courseSelector,
} from "services/course/course.selectors";
import { getListOfLessonsId } from "utils/lessons";
import AskIcon from "assets/img/questionIcon.png";

const renderContent = ({ type, ...data }, handleQuizChange) =>
  ({
    article: <LessonArticle lesson={data} />,
    video: <LessonVideo lesson={data} />,
    upload: <LessonUpload lesson={data} />,
    feedback: (
      <LessonQuiz
        lesson={data}
        onQuizChange={(value) => handleQuizChange(value)}
      />
    ),
    quiz: (
      <LessonQuiz
        lesson={data}
        onQuizChange={(value) => handleQuizChange(value)}
      />
    ),
  }[type]);

const renderFooter = (
  { type, id, material },
  nextLessonId,
  progress,
  onQuizSubmit,
  answers,
  quizResults,
  onFeedbackSubmit,
  feedbackResult
) => {
  if (type === "quiz" && !quizResults) {
    return (
      <Button
        disabled={
          material?.[0]?.questions?.length !== Object.values(answers)?.length
        }
        onClick={onQuizSubmit}
      >
        Submit
      </Button>
    );
  }
  if (type === "feedback" && !feedbackResult) {
    return (
      <Button
        disabled={
          material?.[0]?.questions?.length !== Object.values(answers)?.length
        }
        onClick={onFeedbackSubmit}
      >
        Submit
      </Button>
    );
  }

  return (
    <>
      {nextLessonId ? (
        <Button
          disabled={!progress?.finishedLessons?.includes(id)}
          onClick={() => history.push(pathBuilder.lesson(nextLessonId))}
        >
          Next Lesson
        </Button>
      ) : (
          <Link to={progress?.isCompleted ? urlLocations.finalCongratulation : urlLocations.course} component={Button}>
              Finish
          </Link>
      )}
    </>
  );
};

const Lesson = React.memo(() => {
  const lesson = useSelector(lessonSelector);
  const modules = useSelector(courseSelector)?.modules;
  const course = useSelector(courseSelector) || [];
  const lessonsIDs = getListOfLessonsId(course?.modules);
  const lessonIndex = lessonsIDs?.indexOf(lesson?.id);
  const nextLessonId = lessonsIDs?.[lessonIndex + 1];
  const progress = useSelector(courseProgressSelector);
  const [quizValue, setQuizValue] = useState({});
  const quizResults = useSelector(courseQuizResultSelector);
  const feedbackResult = useSelector(courseFeedbackResultSelector);

  const moduleIndex = modules?.findIndex((module) =>
    module?.lessons?.some((l) => l?.id === lesson?.id)
  );
  const dispatch = useDispatch();

  const handleQuizChange = (values) => {
    setQuizValue(values);
  };

  const onQuizSubmit = () => {
    dispatch(submitCourseQuizAction({ id: lesson?.id, answers: quizValue }));
  };

  const onFeedbackSubmit = () => {
    dispatch(submitFeedbackAction({ id: lesson?.id, answers: quizValue }));
  };

  return (
    <>
      <Button
        onClick={() => history.push(pathBuilder.module(moduleIndex))}
        simplebutton="true"
      >
        <LeftOutlined /> Back to the module
      </Button>
      <Styled.Card>
        <Styled.CardHeader>
          <Styled.Title>{lesson?.title}</Styled.Title>
        </Styled.CardHeader>
        <Styled.CardContent>
          {lesson && renderContent(lesson, handleQuizChange)}
        </Styled.CardContent>
        <Styled.LessonFooter>
          <Styled.CardContent>
            <Styled.LessonFooterInner>
              {/*<Link*/}
              {/*  to={pathBuilder.askQuestion(lesson?.id)}*/}
              {/*  primary="true"*/}
              {/*  component={Button}*/}
              {/*>*/}
              {/*  Ask Prof. Enver*/}
              {/*  <img src={AskIcon} alt="" />*/}
              {/*</Link>*/}
              {lesson &&
                renderFooter(
                  lesson,
                  nextLessonId,
                  progress,
                  onQuizSubmit,
                  quizValue,
                  quizResults,
                  onFeedbackSubmit,
                  feedbackResult
                )}
            </Styled.LessonFooterInner>
          </Styled.CardContent>
        </Styled.LessonFooter>
      </Styled.Card>
    </>
  );
});

export default Lesson;
