import { createAction } from "redux-actions";

export const GET_COURSE = "GET_COURSE";
export const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS";
export const GET_COURSE_FAILURE = "GET_COURSE_FAILURE";

export const GET_PROGRESS = "GET_PROGRESS";
export const GET_PROGRESS_SUCCESS = "GET_PROGRESS_SUCCESS";
export const GET_PROGRESS_FAILURE = "GET_PROGRESS_FAILURE";

export const UPDATE_PROGRESS = "UPDATE_PROGRESS";
export const UPDATE_PROGRESS_SUCCESS = "UPDATE_PROGRESS_SUCCESS";
export const UPDATE_PROGRESS_FAILURE = "UPDATE_PROGRESS_FAILURE";

export const SUBMIT_COURSE_QUIZ = "SUBMIT_COURSE_QUIZ";
export const SUBMIT_COURSE_QUIZ_SUCCESS = "SUBMIT_COURSE_QUIZ_SUCCESS";
export const SUBMIT_COURSE_QUIZ_FAILURE = "SUBMIT_COURSE_QUIZ_FAILURE";

export const SUBMIT_FEEDBACK = "SUBMIT_FEEDBACK";
export const SUBMIT_FEEDBACK_SUCCESS = "SUBMIT_FEEDBACK_SUCCESS";
export const SUBMIT_FEEDBACK_FAILURE = "SUBMIT_FEEDBACK_FAILURE";

export const CLEAR_QUIZ_RESULT = "CLEAR_QUIZ_RESULT";

export const updateProgressAction = createAction(UPDATE_PROGRESS);
export const submitCourseQuizAction = createAction(SUBMIT_COURSE_QUIZ);
export const submitFeedbackAction = createAction(SUBMIT_FEEDBACK);
