import React from "react";
import { useLocation } from "react-router";

import { history, urlLocations } from "routes/urlLocations";
import { STORAGE_TYPE } from "constants/constants";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Styled from "./AuthorizedLayout.Styled";

const AuthorizedLayout = ({ children, headerWhite }) => {
  const MainContentRef = React.useRef(null);
  const { pathname } = useLocation();

  React.useEffect(() => {
    const storage =
      localStorage?.getItem("storageTyp") || STORAGE_TYPE.SESSION_STORAGE;
    const jwt = window[storage]?.getItem("jwt");
    !jwt && history.push(urlLocations.login);
  }, []);

  React.useEffect(() => {
    MainContentRef.current?.scrollIntoView();
  }, [pathname]);

  return (
    <Styled.Page>
      <Header headerWhite={headerWhite} />
      <Styled.Content>
        <Styled.Container>{children}</Styled.Container>
      </Styled.Content>
      <Footer />
    </Styled.Page>
  );
};

export default AuthorizedLayout;
