import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import media from "constants/media";

const Card = styled.div`
  padding: 25px 0 60px;
  margin-top: 24px;
  background: #fff;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
`;

const CardHeader = styled.div`
  padding: 0 30px 25px;
  border-bottom: 1px solid #d6dee1;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const ModuleCount = styled.div`
  display: inline-block;
  color: var(--grey);
  margin-right: 15px;
`;

const List = styled.div`
  padding: 48px 24px 0;
  ${media.m} {
    padding: 15px;
  }
  ${media.s} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
`;

const ModuleLesson = styled(Link)`
  position: relative;
  display: inline-block;
  margin: 0 40px 64px;
  vertical-align: top;
  cursor: pointer;
  font-size: 12px;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
    `}
  img {
    display: block;
    max-width: 95px;
  }
`;

const LessonCount = styled.div`
  color: var(--grey);
  padding-left: 8px;
`;

const LessonName = styled.div`
  font-size: 14px;
  padding-left: 8px;
  color: ${({ isCompleted }) =>
    `${isCompleted ? `var(--green)` : `var(--dark)`}`};
`;

const LessonComplete = styled.div`
  position: absolute;
  left: 27px;
  top: 42px;
  padding: 2px 8px;
  width: 105px;
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
  background: var(--green);
  border: 1px solid #fff;
  display: inline-block;
  .anticon {
    margin-right: 6px;
  }
`;

export default {
  Card,
  CardHeader,
  Title,
  ModuleCount,
  List,
  ModuleLesson,
  LessonCount,
  LessonName,
  LessonComplete,
};
