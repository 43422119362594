import React from "react";

const Privacy = React.memo(() => {
  return (
    <div>
      <h1>Privacy</h1>
      <p>
        Pfizer respects the privacy of its Website users and works to ensure data you
        provide is held securely. We will use your data to register you for the selected
        webinar and get request your feedback on the quality of the session. We will share
        registrant and attendance information with Pfizer staff organizing the webinar in your
        country. Please refer to Pfizer’s Terms &amp; Conditions and Privacy Policy in your
        country, which explains users&#39; rights and responsibilities with respect to information
        that is disclosed on this Website.
      </p>
      <p>
        Please check local countries&#39; privacy policy:
        <br/>
        <a href={'https://www.pfizerprogulf.com/'} target={'_blank'}>https://www.pfizerprogulf.com/</a>
      </p>
    </div>
  );
});

export default Privacy;
