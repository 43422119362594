import React from "react";
import {useSelector} from "react-redux";

import Styled from "./Main.Styled";
import {courseSelector} from "services/course/course.selectors";

const MainIntro = React.memo(() => {
    const introVideo = useSelector(courseSelector)?.introVideo;

    return (
        <Styled.Page>
            <Styled.Container>
                <Styled.IntroSection>
                    <Styled.IntroInfo>
                        <Styled.IntroTitle>
                            Supply <span className="orange">chain education</span> program
                        </Styled.IntroTitle>
                        <Styled.IntroDescription>
                            <p>It’s a six months program customized to suit the needs of Payers & Pharmacists in Gulf
                                Countries, to manage better forecasting, enhance productivity, improve quality &
                                efficiency of operations, thereby facilitating timely access of medications, resulting
                                in improved patient centric services.
                            </p>
                            <p>Delivery of the 6 months program by INSEAD aims to focus on current met challenges in
                                supply chain by covering the below topics.
                            </p>
                            <ul>
                                <li>VALUE-BASED MANAGEMENT.</li>
                                <li>COST-SERVICE TRADE OFF.</li>
                                <li>FORECASTING AND OPERATIONAL AGILITY.</li>
                                <li>INCENTIVE DESIGN (VALUE CAPTURE).</li>
                                <li>DEMAND PLANNING AND PROCUREMENT OPTIMIZATION.</li>
                                <li>NEW TRENDS AND TECHNOLOGIES.</li>
                            </ul>
                        </Styled.IntroDescription>
                    </Styled.IntroInfo>
                    <Styled.IntroVideo>
                        <video loop preload={true} controls={true} src={introVideo?.url}>
                            <source src={introVideo?.url} type="video/mp4"/>
                            <source src={introVideo?.url} type="video/ogg"/>
                        </video>
                    </Styled.IntroVideo>
                </Styled.IntroSection>
            </Styled.Container>
        </Styled.Page>
    );
});

export default MainIntro;
