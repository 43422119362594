import React, { useEffect, useState } from "react";
import { Field } from "redux-form";

import Input from "components/Input/Input";
import ControlWrapper from "components/ControlWrapper/ControlWrapper";

const CustomInput = ({
  input: { name, value },
  input,
  formatMessage,
  setTouched,
  placeholder,
  type,
  errors,
  label,
  typeUi,
  customTouched,
  ...meta
}) => (
  <>
    <ControlWrapper
      name={name}
      customTouched={customTouched}
      error={meta.meta.error}
      touched={meta.meta.touched}
    >
      <Input
        className={typeUi}
        {...input}
        {...meta}
        onFocus={setTouched.bind(true)}
        type={type}
        placeholder={placeholder}
        label={label}
        value={value}
      />
    </ControlWrapper>
  </>
);

const InputField = ({
  name,
  errors,
  value,
  placeholder,
  type,
  label,
  typeUi,
  ...meta
}) => {
  const [customTouched, setTouched] = useState(false);

  useEffect(() => {
    setTouched(false);
  }, [errors]);

  return (
    <Field
      component={CustomInput}
      name={name}
      placeholder={placeholder}
      type={type}
      label={label}
      customTouched={customTouched}
      setTouched={setTouched}
      errors={errors}
      className={typeUi}
      value={value}
      {...meta}
    />
  );
};

export default InputField;
