import { call, put, takeEvery, select } from "redux-saga/effects";
import { createMatchSelector, LOCATION_CHANGE } from "connected-react-router";
import get from "lodash/get";

import {
  finishLoaderAction,
  startLoaderAction,
} from "services/loader/loader.action";

import {
  GET_LESSON,
  GET_LESSON_SUCCESS,
  GET_LESSON_FAILURE,
  SUBMIT_LESSON_UPLOADS,
  SUBMIT_LESSON_UPLOADS_FAILURE,
  SUBMIT_LESSON_UPLOADS_SUCCESS,
  GET_LESSON_UPLOADS,
  GET_LESSON_UPLOADS_SUCCESS,
  GET_LESSON_UPLOADS_FAILURE,
} from "./lesson.actions";

import {
  getLessonRequest,
  submitLessonUploadsRequest,
  getLessonUploadsRequest,
} from "./lesson.api";
import { isUrlMatch } from "../router/router.utils";
import { urlLocations } from "routes/urlLocations";

function* getLessonOnLocationsChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.lessonInfo)) {
      const getMatch = createMatchSelector(urlLocations.lessonInfo);
      const state = yield select((state) => state);
      const id = get(getMatch(state), "params.id");
      if (!!id) {
        yield put({ type: GET_LESSON, payload: id });
        yield put({ type: GET_LESSON_UPLOADS, payload: id });
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchLesson({ payload: id }) {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(getLessonRequest, id);
    yield put({
      type: GET_LESSON_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: GET_LESSON_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* submitLessonUpload({ payload }) {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(submitLessonUploadsRequest, payload);
    yield put({
      type: SUBMIT_LESSON_UPLOADS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: SUBMIT_LESSON_UPLOADS_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* getLessonUploads({ payload }) {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(getLessonUploadsRequest, payload);
    yield put({
      type: GET_LESSON_UPLOADS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: GET_LESSON_UPLOADS_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* lessonSaga() {
  yield takeEvery(LOCATION_CHANGE, getLessonOnLocationsChange);
  yield takeEvery(GET_LESSON, fetchLesson);
  yield takeEvery(SUBMIT_LESSON_UPLOADS, submitLessonUpload);
  yield takeEvery(GET_LESSON_UPLOADS, getLessonUploads);
}
