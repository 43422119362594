import {
  GET_LESSON_SUCCESS,
  GET_LESSON_UPLOADS_SUCCESS,
} from "./lesson.actions";
import initialState from "../../store/initialState";

export default (state = initialState.lesson, action) => {
  switch (action.type) {
    case GET_LESSON_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_LESSON_UPLOADS_SUCCESS:
      return {
        ...state,
        uploads: action.payload?.uploads,
      };
    default:
      return state;
  }
};
