import { put, takeEvery } from "redux-saga/effects";

import { fileUploadRequest } from "./filesUpload.api";
import {
  UPLOAD_FILE,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_SUCCESS,
  CLEAR_FILES,
} from "./filesUpload.actions";
import { finishLoaderAction, startLoaderAction } from "../loader/loader.action";
import { LOCATION_CHANGE } from "connected-react-router";
import { isUrlMatch } from "services/router/router.utils";
import { urlLocations } from "routes/urlLocations";

function* clearFilesOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.lessonInfo)) {
      yield put({ type: CLEAR_FILES });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fileUpload({ payload }) {
  try {
    yield put(startLoaderAction());
    const { response } = yield fileUploadRequest(payload.file);

    yield put({
      type: UPLOAD_FILE_SUCCESS,
      payload: {
        field: payload.field,
        file: response.data[0],
      },
    });
  } catch (error) {
    yield put({ type: UPLOAD_FILE_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* fileUploadSaga() {
  yield takeEvery(UPLOAD_FILE, fileUpload);
  yield takeEvery(LOCATION_CHANGE, clearFilesOnLocationChange);
}
