import React, { useRef } from "react";

import LoginForm from "./Login.Form/Login.Form";
import Styled from "./Login.Styled";
import LoginDisclaimer from "containers/Login/Login.Disclaimer/Login.Disclaimer";

const Login = React.memo(() => {
  let buttonRef = useRef(null);

  const onSubmit = () => {
    buttonRef.current.click();
  };

  const setRef = (ref) => {
    buttonRef.current = ref;
  };

  return (
    <>
      <Styled.Title>Change the way learning is valued</Styled.Title>
      <LoginForm setRef={setRef} />
      <LoginDisclaimer onSubmit={onSubmit} />
    </>
  );
});

export default Login;
