import {
  CLEAR_FILES,
  UPDATE_FILES,
  UPLOAD_FILE_SUCCESS,
} from "./filesUpload.actions";
import initialState from "../../store/initialState";

export default (state = initialState.files, action) => {
  switch (action.type) {
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        [action.payload?.field]: [
          ...(state?.[action.payload?.field]
            ? state?.[action.payload?.field]
            : []),
          action.payload?.file,
        ],
      };
    case UPDATE_FILES:
      return {
        ...state,
        [action.payload?.field]: action.payload?.files,
      };
    case CLEAR_FILES:
      return null;
    default:
      return state;
  }
};
