import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export const urlLocations = {
  login: "/",
  terms: "/terms",
  privacy: "/privacy",
  main: "/main",
  course: "/course",
  module: "/module",
  moduleInfo: "/module/:index",
  lesson: "/lesson",
  lessonInfo: "/lesson/:id",
  askQuestionPath: "/question",
  askQuestion: "/question/:lessonId",
  finalCongratulation: "/final-сongratulation",
};

export const pathBuilder = {
  module: (index) => `${urlLocations.module}/${index}`,
  lesson: (id) => `${urlLocations.lesson}/${id}`,
  askQuestion: (lessonId) => `${urlLocations.askQuestionPath}/${lessonId}`,
};
