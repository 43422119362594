import styled from "styled-components";
import successBG from "../../assets/img/successBG.jpg";

const Card = styled.div`
  padding: 25% 0 60px;
  background: #fff;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  background-image: url(${successBG});
  background-position: top;
  background-size: 100%;
  text-align: center;
  background-repeat: no-repeat;
`;

const Title = styled.div`
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 600;
`;

const Desc = styled.div`
  max-width: 400px;
  margin: 0 auto;
`;

export default {
  Card,
  Title,
  Desc,
};
