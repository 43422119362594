import React from "react";

const Terms = React.memo(() => {
    return (
        <div>
            <h1>Contact Us</h1>
            <h2>
                Pfizer International Corporation – Dubai Branch
            </h2>
            <p>
                Sheikh Zayed Road, Al Moosa Tower 2
            </p>
            <p>
                P.O. Box: 29553, Dubai, UAE
            </p>
            <p>
                Tel:<a href="tel:+97143322286">+971-4-3322286</a>, Fax:<a href="tel:+97143320212">+971-4-3320212</a>
            </p>
            <br/>
            <p>
                Pfizer Identification Number: <b>PP-ABV-BHR-0001</b>
            </p>
        </div>
    );
});

export default Terms;
