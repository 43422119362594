import React from "react";
import Styled from "./FinalCongratulation.Styled";

const FinalCongratulation = React.memo(() => {
  return (
    <Styled.Card>
      <Styled.Title>Congratulation!</Styled.Title>
      <Styled.Desc>
        We will send your certificate on your registered email
      </Styled.Desc>
    </Styled.Card>
  );
});

export default FinalCongratulation;
